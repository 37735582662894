<template>
  <section class="pt-50 pb-5">
    <div
      class="container pt-5 d-flex align-items-center justify-content-center m-auto login"
      style="min-height: 65vh"
    >
      <div class="card m-auto col-md-3 p-5">
        <div class="text-center intro">
          <img src="@/assets/img/logo.png" width="160" class="m-auto" />
        </div>
        <validation-observer ref="observer" v-slot="{ handleSubmit }">
          <form @submit.prevent="handleSubmit(submit)">
            <div class="mt-4 text-center">
              <h4>Change Password</h4>
              <validation-provider
                v-slot="{ errors, pristine }"
                name="New Password"
                rules="required"
              >
                <div class="mt-3 inputbox">
                  <input
                    :readonly="error"
                    type="password"
                    class="form-control"
                    name="newpassword"
                    placeholder="New Password"
                    v-model="form.newpassword"
                    :class="{
                      'is-invalid': errors[0],
                      'is-valid': !errors[0] && !pristine,
                    }"
                  />
                  <i class="fa fa-lock"></i>
                  <div class="invalid-feedback" v-if="!error">
                    {{ errors[0] }}
                  </div>
                </div>
              </validation-provider>
            </div>

            <div class="mybtn m-auto mt-3 d-flex justify-content-between">
              <button
                v-if="!loading"
                :disabled="error"
                type="submit"
                class="btn btn-primary rounded-0 px-5 mb-2 mb-lg-0 me-3 addnewbtn allbtn"
              >
                <span class="d-flex align-items-center"
                  ><span class="small">Reset</span></span
                >
              </button>
              <button v-else class="btn btn-primary" type="button" disabled>
                <span
                  class="spinner-border spinner-border-sm"
                  role="status"
                  aria-hidden="true"
                ></span>
                Loading...
              </button>
              <button
                type="button"
                @click="$router.push('/')"
                class="btn btn-danger rounded-0 px-5 mb-2 mb-lg-0 me-3 allbtn"
              >
                <span class="d-flex align-items-center"
                  ><span class="small">Cancel</span></span
                >
              </button>
            </div>
          </form>
        </validation-observer>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "PasswordResetRequest",
  data() {
    return {
      form: {
        newpassword: "",
      },
      token: "",
      error: false,
      errorMessage: "",
      loading: false,
    };
  },
  methods: {
    async submit() {
      this.loading = true;
      let data = new FormData();
      data.append("password", this.form.newpassword);
      data.append("token", this.token);
      await this.$repository.password
        .confirm(data)
        .then((res) => {
          if (res.status === 200) {
            this.loading = false;
            this.$toast.success("Password Changed Successfully");
            this.$router.push("/login");
          }
        })
        .catch((err) => {
          this.loading = false;
          if (err.response.data.password) {
            this.$toast.error(err.response.data.password[0]);
          } else {
            this.$toast.error("Something went Wrong");
          }
        });
    },
  },

  created() {
    const token = this.$route.query.token;
    let data = new FormData();
    data.append("token", token);
    this.$repository.password
      .validate(data)
      .then(() => {
        this.token = token;
      }) // valid token
      .catch((err) => {
        if (err.response.data.token) {
          this.$toast.error(err.response.data.token[0]);
          this.errorMessage = err.response.data.token[0];
        } else if (err.response.data.detail) {
          this.$toast.error(err.response.data.detail);
          this.errorMessage = err.response.data.detail;
        } else {
          this.$toast.error("Something went wrong");
        }

        this.error = true;
      });
  },
};
</script>

<style scoped></style>
